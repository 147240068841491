@import "src/style/utils/mediaQueries";
@import "src/style/global/tag";

.FormInputTel {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-01);
  color: var(--color-inputField-text);
  transition: var(--easing-time-01) all ease-in-out;

  .special-label {
    display: none;
  }

  .form-control {
    width: 100% !important;
    border: 0 !important;
    border-radius: var(--border-radius-01) !important;
    position: relative !important;
    font-size: initial !important;
    letter-spacing: initial !important;
    margin: 0 !important;
    padding-left: 48px !important;
    background: inherit !important;
    line-height: inherit !important;
    height: auto !important;
  }

  .flag-dropdown,
  .selected-flag {
    border-radius: var(--border-radius-01) 0 0 var(--border-radius-01) !important;
    background-color: var(--color-white);
  }

  .flag-dropdown {
    z-index: inherit !important;
    top: 2px !important;
    left: 2px !important;
    bottom: 2px !important;
    border: 0 !important;
    border-right: 1px solid var(--color-border-default) !important;
  }

  .country-list {
    font-family: var(--font-family);
  }

  .search-box {
    margin-left: 0 !important;
    width: calc(100% - 8px);
  }

  &.has-error {
    box-shadow: var(--color-border-error) inset 0 0 0 1px;
    color: var(--color-text-error);
  }
}