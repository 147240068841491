@import "src/style/utils/mediaQueries";
@import "src/style/global/tag";

.CandidaturesSingleLayoutSidebar {
  &-etapesList {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-05);
  }

  &-button {
    cursor: not-allowed;
    display: flex;
    align-items: center;
    gap: var(--spacing-03);

    &.is-passed,
    &.is-current {
      cursor: pointer;
      font-weight: 500;
      color: var(--color-text-brand);
    }

    &.is-unlocked {
      cursor: pointer;
    }
  }

  &-buttonLockedIcon {
    .is-current &,
    .is-passed &,
    .is-unlocked & {
      display: none;
    }
  }

  &-buttonBefore {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      .is-passed &,
      .is-unlocked &,
      .is-current & {
        content: "";
        display: block;
        flex: 1 0 auto;
        width: 16px;
        height: 16px;
        margin: 5px;
        border-radius: 50%;
      }

      .is-unlocked & {
        box-shadow: var(--color-text-primary) inset 0 0 0 2px;
      }

      .is-passed & {
        box-shadow: var(--color-text-brand) inset 0 0 0 2px;
      }

      .is-current & {
        box-shadow: var(--color-text-brand) inset 0 0 0 2px;
        background-color: var(--color-text-brand);
      }
    }
  }
}