@import "src/style/utils/mediaQueries";

.CandidaturesSingleLayout {
  min-height: 100dvh;
  display: flex;

  @include tablet {
    flex-direction: column;
  }

  &-sidebar {
    position: sticky;
    top: 0;
    width: 380px;
    max-height: 100dvh;
    border-right: 1px solid var(--color-background-01);
    flex: 1 0 auto;

    @include tablet {
      display: none;
    }
  }

  &-sidebarHeader {
    border-bottom: 1px solid var(--color-background-01);
    display: flex;
    justify-content: flex-end;
    padding: var(--spacing-08);
  }

  &-sidebarContent {
    padding: var(--spacing-07);
  }

  &-content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &-contentHeader {
    z-index: 1;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    height: 129px;
    border-bottom: 1px solid var(--color-background-01);
    background-color: var(--color-background-default);
    padding: var(--spacing-05);

    @include tablet {
      display: none;
    }
  }

  &-mobileNavigation {
    display: none;
    z-index: 3;
    position: sticky;
    top: 0;
    background-color: var(--color-background-default);

    @include tablet {
      display: block;
      width: 100%;
    }
  }

  &-contentForm {
    padding: var(--spacing-07);
    max-width: 780px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include tablet {
      padding: 0;
    }
  }
}