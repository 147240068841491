@import "src/style/utils/mediaQueries";

.CandidaturesSingleMetiers {
  @include tablet {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &-metiersAfterFormationInputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-04);

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mobile {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}