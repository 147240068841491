@import "src/style/utils/mediaQueries";

.CandidaturesInitialisationForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-05);
  padding: var(--spacing-08);
  background-color: var(--color-background-default);

  @include tablet {
    height: auto;
    gap: 0;
    padding: 0;
  }

  &-head {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-03);

    @include tablet {
      z-index: 1;
      position: sticky;
      top: 0;
      gap: var(--spacing-05);
      padding: var(--spacing-05);
      background-color: var(--color-background-default);
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    }
  }

  &-headRowTitre {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-closeFormOnMobile {
    display: none;

    @include media(1150px) {
      display: flex;
    }
  }

  &-titre {
    color: var(--color-text-brand) !important;
  }

  &-sousTitre {
    max-width: 400px;
  }

  &-fieldsetFormation {
    display: flex;
    align-items: flex-end;
    gap: var(--spacing-04);

    @include tablet {
      flex-direction: column;
    }
  }

  &-inputSpecialisation {
    width: 55%;

    @include tablet {
      width: 100%;
    }
  }

  &-inputAnnee {
    width: 45%;

    @include tablet {
      width: 100%;
    }
  }

  &-formFooter {
    @include tablet {
      width: 100%;
    }
  }

  &-buttonSubmit {
    @include tablet {
      width: 100%;
    }
  }
}