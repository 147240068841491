.CandidaturesSinglePiecesJointes {
  position: relative;
  flex-grow: 1;

  &-list {
    display: flex;
    flex-direction: column;
  }

  &-listItem {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-03);

    &:before {
      margin-top: 8px;
      content: "";
      display: block;
      min-width: 5px;
      height: 5px;
      background-color: var(--color-text-brand);
      border-radius: 50%;
    }
  }

  &-input {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-01);
  }
}