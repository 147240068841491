@import "src/style/utils/mediaQueries";

.CandidaturesInitialisationSidebar {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-08) 16px;
  background-image: url("/assets/img/background-decoration.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &-inner {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-06);
  }

  &-titre {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-03);
    text-align: center;
  }

  &-message {
    border-radius: var(--border-radius-03);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-03);
    padding: var(--spacing-06);
    background: var(--color-background-default);
  }

  &-buttonOpenModal {
    display: none;

    @include media(1150px) {
      display: block;
    }
  }
}