@import "src/style/utils/mediaQueries";

.CandidaturesSinglePostSoumission {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-08);

  @include tablet {
    justify-content: flex-start;
    gap: var(--spacing-06);
  }

  &-head {
    width: 100%;
    border-top: 1px solid var(--color-neutral-02);
    border-bottom: 1px solid var(--color-neutral-02);
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      flex-direction: column;
      align-items: initial;
    }
  }

  &-headLeft {
    padding: var(--spacing-05);

    @include tablet {
      border-bottom: 1px solid var(--color-neutral-02);
      display: flex;
      justify-content: center;
    }
  }

  &-headRight {
    border-left: 1px solid var(--color-neutral-02);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--spacing-05);
    padding: var(--spacing-05);

    @include tablet {
      border: 0;
      text-align: center;
    }
  }

  &-content {
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-08);
    text-align: center;

    @include tablet {
      gap: var(--spacing-06);
      padding: 0 var(--spacing-05);
    }
  }

  &-contactContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-04);
  }

  &-contact {
    display: flex;
    align-items: center;
    gap: var(--spacing-03);
  }

  &-bigDot {
    padding-top: 4px;
  }
}