@keyframes fadeInBottom {
  from {
    transform: translateY(3px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.FormTooltip {
  &-icon {
    cursor: pointer;
    margin-top: -4px;
    margin-left: var(--spacing-02);
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }

  &-messageContainer {
    z-index: 2;
    position: absolute;
    bottom: 100%;
    left: calc(0px - 32px);
    width: 280px;
    padding-bottom: 12px;
  }

  &-message {
    border-radius: var(--border-radius-03);
    background-color: var(--color-background-01);
    padding: var(--spacing-04);
    text-decoration: underline;
    animation: fadeInBottom 0.3s ease-in-out forwards;

    &:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 32px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--color-background-01);
    }
  }
}