@import "src/style/utils/mediaQueries";

.InscriptionsHome {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-08) 0;
  min-height: 100dvh;
  background-image: url("/assets/img/background-decoration-inscriptions.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include mobile {
    padding: var(--spacing-06) 0;
  }

  &-logo {
    margin: auto;
    width: fit-content;
  }

  &-logoDesktop {
    @include mobile {
      display: none;
    }
  }

  &-logoMobile {
    @include mobileMin {
      display: none;
    }
  }

  &-title {
    margin-top: 25px;
    text-align: center;

    @include mobile {
      margin-top: 15px;
    }
  }

  &-subtitle {
    margin-top: 25px;
    text-align: center;

    @include mobile {
      margin-top: 15px;
    }
  }

  &-infoMessage {
    margin: 25px auto 0;
    padding: var(--spacing-04);
    border: 2px solid var(--color-brand-50);
    border-radius: var(--border-radius-03);
    max-width: 542px;
    text-align: center;

    @include tablet {
      display: none;
    }
  }

  &-parcours {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    @include tablet {
      margin-top: 25px;
      flex-direction: column;
      gap: 25px;
    }
  }

  &-parcoursItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 590px;
  }

  &-parcoursItemHeader {
    padding: var(--spacing-06);
    border-radius: var(--border-radius-03);
    width: 100%;
    background-color: var(--color-background-default);

    @include tablet {
      padding: var(--spacing-05);
    }
  }

  &-parcoursItemTitle {
    text-align: center;

    @include tablet {
      font-size: var(--font-size-body-s) !important;
    }
  }

  &-parcoursItemText {
    margin-top: 5px;
    text-align: center;

    @include tablet {
      font-size: var(--font-size-body-s) !important;
    }
  }

  &-parcoursItemBtn {
    align-self: auto !important;
  }

  &-line {
    width: 2px;
    height: 250px;
    background-color: var(--color-brand-50);

    @include tablet {
      height: 35px;
    }
  }
}