@import "src/style/utils/mediaQueries";

.CandidaturesSingleExperiencesProfessionnelles {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06);

  @include tablet {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
  }
}