@import "src/style/utils/mediaQueries";

.helpers-badge {
  display: inline-block;
  border-radius: 6px;
  padding: var(--spacing-03) var(--spacing-04);
  font-family: var(--font-family);
  font-size: var(--font-size-body-m);
  font-weight: var(--font-weight-500);

  &.is-AValider {
    border: 2px solid var(--color-warningscale-50);
    color: var(--color-warningscale-50);
    background-color: var(--color-warningscale-10);
  }

  &.is-Valider {
    border: 2px solid var(--color-successscale-50);
    color: var(--color-successscale-50);
    background-color: var(--color-successscale-10);
  }

  &.is-Stopper {
    border: 2px solid var(--color-errorscale-50);
    color: var(--color-errorscale-50);
    background-color: var(--color-errorscale-10);
  }
}

.helpers-ifYes {
  display: block;
}

.helpers-dualInputs {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-05);
  justify-content: flex-end;

  @include tablet {
    flex-direction: column;
  }
}

.helpers-formBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06);

  @include tablet {
    gap: var(--spacing-05);
    padding: var(--spacing-05);
  }
}

.helpers-formFooter {
  margin-top: var(--spacing-05);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-05);

  @include tablet {
    margin-top: 0;
    padding: var(--spacing-05);
  }
}

.helpers-buttonSubmit {
  @include tablet {
    width: 100%;
  }
}

.helpers-display-n {
  display: none;
}

.helpers-container {
  margin: 0 auto;
  width: calc(100% - (80px * 2));
  max-width: 1440px;

  @include tablet {
    width: calc(100% - (var(--spacing-05) * 2));
  }
}