.Error {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-07);

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-button {
    align-self: center !important;
  }
}